@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.molo-card {
  display: flex;
  flex-direction: column-reverse;
  padding: fn.spacing(4);
  column-gap: 2em;
  overflow: hidden;
}

.molo-card.molo-card-reverse {
  padding: fn.spacing(4) fn.spacing(2);
}

.molo-card-box {
  display: flex;
  margin-top: fn.spacing(6.5);
  flex: 1;
}

.molo-card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.molo-card-text-title {
  color: var.$color-primary-achieve-blue;
  letter-spacing: var.$eyebrow-letter-spacing;
}

.molo-card-text-title-black {
  color: var.$color-neutral-black;
  letter-spacing: var.$eyebrow-letter-spacing;
}

.molo-card-text-header {
  margin-top: fn.spacing(2);
}

.molo-card-text-content {
  margin-top: fn.spacing(2);
}

.molo-card-text-link {
  color: var.$color-primary-achieve-blue;
  display: flex;
  margin-top: fn.spacing(3);
}

.link-arrow-right {
  margin-left: fn.spacing(1.5);
}

.molo-card-image {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.molo-card-image-bg {
  position: relative;
}

.molo-card-image-bg span {
  display: block !important;
  height: 280px !important;
  width: 220px !important;
  margin-right: -150px !important;
}

.molo-card-image-bg span img {
  border-radius: fn.spacing(0.5);
}

.molo-card-image-top {
  position: absolute;
  bottom: -20px;
  left: 150px;
  box-shadow: 0 8.6px 69.5px -4.3px rgb(0 0 0 / 13%), 0 13px 19.5px -4.3px rgb(16 24 40 / 6%);
  border: 0.8px solid var.$color-neutral-grey-5;
  border-radius: fn.spacing(1);
  overflow: hidden;
}

.molo-card-image-top span {
  width: 170px !important;
  height: auto !important;
  position: initial !important;
  display: block !important;
  overflow: inherit !important;
}

.molo-card-image-reverse .molo-card-image-bg span {
  box-shadow: 0 8.6px 69.5px -4.3px rgb(0 0 0 / 13%), 0 13px 19.5px -4.3px rgb(16 24 40 / 6%);
  border: 0.8px solid var.$color-neutral-grey-5;
  border-radius: fn.spacing(1);
  background: var.$color-neutral-grey-8 !important;
  width: 194px !important;
}

.molo-card-image-top span img {
  max-height: inherit !important;
  max-width: inherit !important;
  width: 170px !important;
  height: auto !important;
  position: initial !important;
}

.molo-section .molo-card-image-bg span img {
  padding: 10px !important;
}

.molo-card-image::before {
  content: '';
  width: 220px;
  height: 90%;
  position: absolute;
  background: linear-gradient(180deg, rgb(25 56 219 / 15%) 24.21%, rgb(211 86 111 / 15%) 100%);
  filter: blur(75px);
  transform: rotate(-1.29deg);
  bottom: 0;
  left: fn.spacing(2.5);
  top: 13%;
}

.molo-card-image-reverse.molo-card-image::before {
  background: linear-gradient(
    223.13deg,
    rgb(25 56 219 / 15%) 0.62%,
    rgb(33 161 255 / 15%) 54.65%,
    rgb(254 206 47 / 15%) 69.78%
  );
}

.molo-card-image[data-align-left='true'] .molo-card-image::before {
  background: linear-gradient(180deg, rgb(25 56 219 / 30%) 24.21%, rgb(211 86 111 / 30%) 100%);
}

.molo-card-image[data-align-left='true'] .molo-card-box {
  margin-top: fn.spacing(4);
}

.molo-card-image[data-align-left='true'] .molo-card-image .molo-card-image-top {
  box-shadow: none;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0;
  border-radius: 0;
  overflow: inherit;
}

.molo-card-image[data-align-left='true'] .molo-card-image .molo-card-image-top span img {
  box-shadow: 0 8.6px 69.5px -4.3px rgb(0 0 0 / 13%), 0 13px 19.5px -4.3px rgb(16 24 40 / 6%);
  min-height: inherit !important;
  border: 0.8px solid var.$color-neutral-grey-5 !important;
  border-radius: fn.spacing(1);
  overflow: hidden;
}

.buttons {
  height: 38px;
  margin-top: fn.spacing(4);
}

.button-apple {
  height: 38px;
  width: 118px;
}

.button-google {
  width: 134px;
  height: 38px;
  margin-right: fn.spacing(2);
}

.outline-link {
  margin-top: fn.spacing(4);
}

//Tablet styles
@media only screen and (min-width: var.$breakpoint-md) {
  .molo-card {
    min-height: 600px;
    padding: fn.spacing(4) fn.spacing(8);
    flex-direction: row;
  }

  .molo-card.molo-card-reverse {
    padding: fn.spacing(4) fn.spacing(8);
  }

  .molo-card-box {
    margin-top: fn.spacing(0);
  }

  .molo-card-text {
    max-width: 462px;
  }

  .molo-card-text-header {
    margin-top: fn.spacing(3.5);
  }

  .molo-card-text-content {
    margin-top: fn.spacing(3.5);
  }

  .molo-card-image-bg span {
    height: 320px !important;
    width: 250px !important;
    margin-right: 120px !important;
  }

  .molo-card-image-reverse .molo-card-image-bg span {
    width: 228px !important;
  }

  .molo-card-image-top {
    bottom: 70px;
    left: inherit;
    right: 15px;
  }

  .molo-card-image::before {
    width: 250px;
  }

  .molo-card[data-align-left='true'] {
    flex-direction: row-reverse;
    background: var.$color-neutral-grey-8;
  }

  .molo-card[data-align-left='true'] .molo-card-image {
    align-items: flex-start;
  }

  .molo-card[data-align-left='true'] .molo-card-image-top {
    left: 220px;
    box-shadow: none;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 0;
    right: 15px;
    position: absolute;
    overflow: hidden;
    border-radius: fn.spacing(0.5);
  }
}

//Desktop styles
@media only screen and (min-width: var.$breakpoint-lg) {
  .molo-card-image::before {
    width: 360px;
  }

  .molo-card-image-bg span {
    height: 460px !important;
    width: 360px !important;
    margin-right: 150px !important;
  }

  .molo-card-image-top {
    bottom: -10px;
  }

  .molo-card[data-align-left='true'] .molo-card-image-reverse .molo-card-image-top {
    left: 0;
    top: 230px;
    width: 250px;
  }

  .molo-card[data-align-left='true'] .molo-card-image-reverse .molo-card-image-bg {
    left: 230px;
  }

  .molo-card-image-top span {
    width: 270px !important;
  }

  .molo-card-image-top span img {
    width: 270px !important;
  }

  .molo-card-image-reverse span {
    width: 250px !important;
  }

  .molo-card-image-reverse .molo-card-image-bg span {
    margin-right: 220px !important;
    width: 324px !important;
  }

  .molo-card-image-reverse span img {
    width: 250px !important;
  }
}

@media only screen and (max-width: var.$breakpoint-md) {
  .molo-card-image {
    display: block !important;
  }

  .molo-card-image-reverse .molo-card-image-top {
    left: 164px;
  }

  .molo-card[data-align-left='true'] .molo-card-image-reverse .molo-card-image-bg {
    left: 120px;
  }

  .molo-card[data-align-left='true'] .molo-card-image-reverse .molo-card-image-top {
    left: 0;
    top: 115px;
  }
}
