@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.mad-lib-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.mad-lib-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: fn.spacing(5) fn.spacing(4);
  background: var.$color-primary-highlight-blue;
  width: 100%;
  border-radius: var.$achieve-default-border-radius;

  .mad-lib-btn {
    padding: fn.spacing(1.5) fn.spacing(3);
  }
}

.mad-lib-row {
  align-self: flex-start;
  margin-bottom: fn.spacing(4);
  text-align: left;
  width: 100%;

  &:last-child {
    margin-top: fn.spacing(1.25);
    margin-bottom: 0;
    text-align: center;
  }
}

.mad-lib-title {
  text-align: center;
  margin-bottom: fn.spacing(4);
}

.mad-lib-leading-text {
  margin-right: fn.spacing(1.5);
}

.mad-lib-progress-spinner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.mad-lib-results-header {
  padding: 0 fn.spacing(3);
  text-align: center;
}

.reset-link {
  cursor: pointer;
  display: inline-flex;
  margin-bottom: fn.spacing(5);

  span {
    margin-right: fn.spacing(1);
  }
}

.mad-lib-results-subtitle {
  margin-bottom: fn.spacing(4);
  text-align: center;
}

.mad-lib-results-grid-item {
  &:not(:first-child) {
    border-top: 1px solid var.$color-primary-border-disabled;
  }

  &[data-mobile-hidden='true'] {
    display: none;
  }
}

.mad-lib-results-card {
  margin: 0 (2 * var.$spacing);
}

.select-icon {
  fill: var.$color-primary-achieve-blue;
  height: 24px;
  width: 24px;
}

.select-text {
  padding-right: fn.spacing(2);
  white-space: initial;
}

.mobile-expand-link {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: fn.spacing(4);

  span {
    margin-bottom: fn.spacing(1);
  }
}

.select-sun {
  &::before {
    border-bottom: none;
  }
}

.mad-lib-results {
  background: var.$color-neutral-white;
  width: 100%;
  border-radius: var.$achieve-default-border-radius;

  .mad-lib-title {
    padding: fn.spacing(4) fn.spacing(4) 0;
  }
}

.mad-lib-layout {
  overflow: hidden;
  height: auto;
  flex: 1;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .mad-lib-layout {
    margin-top: inherit;
  }

  .mad-lib-form {
    padding: 0;
  }

  .mad-lib-form,
  .mad-lib-results {
    margin: 0;
    border-radius: 0;
  }

  .mad-lib-title {
    margin-bottom: fn.spacing(4);
  }

  .mad-lib-results {
    background: var.$color-primary-highlight-blue;
    background: transparent;

    .mad-lib-title {
      padding: 0;
      margin-bottom: fn.spacing(8);
    }
  }

  .mad-lib-row {
    align-self: initial;
    margin-bottom: fn.spacing(4);
    text-align: center;

    &:last-child {
      margin-top: fn.spacing(4);
      margin-bottom: 0;
    }
  }

  .select-text {
    padding-right: fn.spacing(4);
    white-space: nowrap;
  }

  .select-icon {
    height: 48px;
    margin-top: fn.spacing(-1);
    width: 48px;
  }

  .mad-lib-results-header {
    padding: 0;
  }

  .mad-lib-results-grid-item {
    display: flex;
    justify-content: center;

    &:not(:first-child) {
      border-top: none;
    }

    &[data-mobile-hidden='true'] {
      display: flex;
      justify-content: center;
    }
  }

  .mobile-expand-link {
    display: none;
  }

  .select-sun {
    &::before {
      border-bottom: 1px solid var.$color-neutral-text-weak;
    }
  }

  .reset-link {
    margin-top: fn.spacing(8);
    margin-bottom: 0;
  }
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoom-out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}
