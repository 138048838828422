@use 'styles/variables' as var;
@use 'styles/functions' as fn;

//Mobile styles
.closer-statement-section {
  background-color: var.$color-achieve-closer-eighty;
  color: var.$color-neutral-white;
  overflow: hidden;
  display: flex;

  .closer-statement-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    .closer-statement-content {
      padding: fn.spacing(5) fn.spacing(5) 0 fn.spacing(5);
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      width: auto;

      .title {
        margin-bottom: fn.spacing(1);
      }

      .description {
        margin-bottom: fn.spacing(1);
      }
    }

    .closer-statement-image-bg {
      display: flex;
      justify-content: center;
    }
  }
}

.buttons {
  height: 38px;
  margin-top: fn.spacing(3);
}

.button-apple {
  height: 38px;
  width: 118px;
  margin-right: fn.spacing(2);
}

.button-google {
  width: 134px;
  height: 38px;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .closer-statement-section {
    background: linear-gradient(90deg, rgb(44 108 208 / 100%) 0%, rgb(64 139 241 / 100%) 70%);

    .closer-statement-box {
      margin-left: fn.spacing(12);
      flex-direction: row;
      align-items: inherit;

      .closer-statement-content {
        padding: 0;
        width: 420px;
        height: 440px;
        max-height: 440px;

        .title {
          margin-bottom: fn.spacing(2);
        }

        .description {
          margin-bottom: fn.spacing(2);
        }
      }

      .closer-statement-image-bg {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}

/* Desktop (Xlarge or greater) styles */
@media screen and (min-width: var.$breakpoint-xl) {
  .closer-statement-section {
    .closer-statement-box {
      margin-left: fn.spacing(17);

      .closer-statement-content {
        padding: 0;
        width: 550px;
      }
    }
  }
}
