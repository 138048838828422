@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.sms-disclosures-section {
  margin-bottom: fn.spacing(4);

  .sms-disclosures-title {
    padding: fn.spacing(4) fn.spacing(0);
  }
}

// Style changes for desktop styles, larger than large breakpoint
@media only screen and (min-width: var.$breakpoint-lg) {
  .sms-disclosures-section {
    .sms-disclosures-title {
      padding: fn.spacing(8) 0;
    }
  }
}
