@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  background-color: var.$color-primary-highlight-blue;
  padding-top: fn.spacing(4);
  padding-bottom: fn.spacing(4);
}

.paper {
  border: 1px solid var.$color-paper-white;
  border-radius: var.$achieve-default-border-radius;
  padding: fn.spacing(5);
}

.paper-carousel-title-divider {
  margin-bottom: fn.spacing(6);
}

.image {
  border-radius: 12px;
}

.card-text-grid {
  padding-left: fn.spacing(5);
  padding-right: fn.spacing(5);
  text-align: center;
}

.lead-text {
  color: var.$color-neutral-text-weak;
  margin-bottom: fn.spacing(1);
}

.heading-text {
  color: var.$color-neutral-text-onlight;
  display: inline;
}

.supporting-text {
  color: var.$color-neutral-eighty;
  margin: fn.spacing(2) 0;
}

.title-link:hover {
  text-decoration-color: var.$color-neutral-text-onlight;
}

.link {
  text-decoration: none;

  p {
    margin: 0;
  }
}

.shadow-selected .selected-item .paper {
  box-shadow: 0 3.3px 18px -3px rgb(16 24 40 / 9%), 0 5px 6px -2px rgb(16 24 40 / 4%);
}

.grid-item-image {
  width: inherit;
  height: inherit;
  padding: 0 0 fn.spacing(4);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .outer-grid-container {
    height: 339px;
  }

  .grid-item-image {
    padding: 0 fn.spacing(4) 0 0;
    width: calc(100% * 7 / 12);
  }

  .card-text-grid {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }

  .section {
    padding-top: fn.spacing(8);
    padding-bottom: fn.spacing(8);
  }

  .paper-carousel-title-divider {
    margin-bottom: fn.spacing(8);
  }

  .lead-text {
    color: var.$color-neutral-gray-eighty;
    margin-bottom: fn.spacing(2);
  }

  .supporting-text {
    color: var.$color-neutral-black;
    margin: fn.spacing(3) 0;
  }
}
