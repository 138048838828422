@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  position: relative;
}

.section-inner {
  min-height: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-good-app .section-inner {
  min-height: 617px;
}

.content-container {
  position: relative;
  height: 100%;
}

.copy {
  padding: fn.spacing(3) fn.spacing(3);

  > *:not(:last-child) {
    margin-bottom: fn.spacing(1.5);
  }
}

.buttons {
  height: 38px;
  margin-top: fn.spacing(4);
}

.button-apple {
  height: 38px;
  width: 118px;
  margin-right: fn.spacing(2);
}

.button-google {
  width: 134px;
  height: 38px;
}

.hero-image-grid-item {
  position: relative;
}

.hero-image-container {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 197px;
  height: 394px;
  margin-bottom: fn.spacing(3);
}

.section-good-app .hero-image-container {
  height: 250px;
  top: fn.spacing(0);
}

.hero-video-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  mask-image: url('/iphone-video-mask.svg');
  mask-position: center top;
  mask-repeat: no-repeat;
  mask-size: cover;
}

.video {
  width: 197px;
  height: 394px;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .copy {
    padding: fn.spacing(3) 0;
  }

  .hero-image-container {
    position: absolute;
    height: 600px;
    width: 300px;
    margin-bottom: 0;
  }

  .video {
    height: 600px;
    width: 300px;
  }

  .section-good-app .hero-image-container {
    width: 340px;
    top: fn.spacing(-6);
    height: 394px;
  }
}
