@use 'styles/variables';
@use 'styles/functions';

.link-text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.card-eyebrow {
  margin-top: functions.spacing(3);
  margin-bottom: functions.spacing(0.5);
}

.image-container {
  border-radius: variables.$achieve-slightly-bigger-border-radius;
  overflow: hidden;
}

.card-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.card-container {
  max-width: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.card-title {
  color: variables.$color-neutral-black;
  text-decoration: none;
  text-align: left;
  margin-top: functions.spacing(2);
  margin-bottom: functions.spacing(2);
}

.card-text {
  margin-bottom: functions.spacing(2);
}

.anchor-links {
  text-decoration: none;

  &:hover {
    color: variables.$color-neutral-black;
  }
}
