@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.home-mad-lib-container {
  width: 100%;
  overflow: visible; // Allow negative margin to pull up the MadLib container over the home hero carousel
  padding: fn.spacing(14) 0 0 0; // extra top padding to account for hero floating text box
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .home-mad-lib-container {
    padding: fn.spacing(8) 0;
  }
}
