@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  padding: fn.spacing(4) 0;
}

.section-breakline {
  padding: fn.spacing(6) fn.spacing(5.75);
}

.grid-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: fn.spacing(4);
}

.card-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  .phone-number-grid-separator {
    display: none;
  }

  &.breakline {
    .phone-number-grid-card {
      align-self: center;
    }

    .phone-number-grid-separator {
      display: flex;
      min-width: 285px;
      min-height: 93px;
      align-items: center;

      div {
        width: 100%;
        border-bottom: 0.5px solid var.$color-neutral-gray-eighty;
      }
    }
  }
}

.typography {
  min-width: 105px;
  text-align: center;
}

.title-divider-wrapper {
  margin-bottom: 0;
}

/* Tablet (large or greater) styles */
@media screen and (min-width: var.$breakpoint-sm) {
  .section,
  .section-breakline {
    display: flex;
    justify-content: center;

    .container-grid {
      .title-divider-wrapper {
        h2 {
          padding: 0;
        }
      }
    }
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .grid-wrapper {
    margin-bottom: fn.spacing(2);
  }

  .section,
  .section-breakline {
    padding: fn.spacing(8);

    .container-grid {
      max-width: 100%;
    }
  }

  .phone-number-title-divider {
    margin-bottom: fn.spacing(7);
  }

  .title-divider-wrapper {
    max-width: fn.spacing(105);
  }

  .card-grid {
    flex-direction: row;
    justify-content: center;

    .phone-number-grid-card {
      width: 327px;
    }

    .phone-number-grid-separator::before {
      content: '';
      width: 0;
      height: 100%;
      border-left: 0.5px solid var.$color-neutral-gray-eighty;
    }

    .phone-number-grid-separator,
    &.breakline .phone-number-grid-separator {
      display: flex;
      justify-content: center;
      min-width: 93px;
      width: 93px;
    }
  }
}

.card {
  display: flex;
  flex-direction: row;
  margin-bottom: fn.spacing(4.5);

  .card-info {
    flex: auto;
    display: flex;
    flex-direction: column;

    .card-text-subtitle {
      margin-top: fn.spacing(2);
    }
  }

  &.breakline {
    flex-direction: column;
    align-items: center;
    margin-bottom: fn.spacing(0);

    .card-info {
      text-align: center;

      .card-text-title {
        margin-top: fn.spacing(2.5);
      }
    }
  }
}

.card-content {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  padding: fn.spacing(2);
  text-align: center;

  &:last-child {
    padding-bottom: fn.spacing(2);
  }
}

.title-typography {
  margin-bottom: fn.spacing(2);
  text-align: center;
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .card {
    flex-direction: column;
    margin-bottom: 0;

    .card-info {
      text-align: center;
      margin-top: fn.spacing(3);
    }

    &.breakline {
      .card-info {
        .card-text-title {
          margin-top: 0;
        }
      }
    }
  }
}
