@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.grid-title {
  margin-top: fn.spacing(8);
  text-align: center;
}

.grid-section {
  margin-bottom: fn.spacing(8);
}

.employee-card-grid {
  display: flex;
  margin-top: fn.spacing(6);
}
