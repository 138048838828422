@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.partners-section {
  padding: fn.spacing(8) fn.spacing(2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partners-section-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partners-section-header {
  padding-bottom: fn.spacing(2);
  text-align: center;
}

.partners-section-title {
  padding-bottom: fn.spacing(2);
  text-align: center;
}

.partners-section-subtitle {
  padding-bottom: fn.spacing(4);
  text-align: center;
  max-width: 760px;
}

.box-text {
  margin-bottom: fn.spacing(1);
}

.box-input input,
.box-textarea {
  background-color: var.$color-neutral-background-default;
}

.submit-button {
  margin-top: fn.spacing(3);
}

.sent-title {
  text-align: center;
}

@media screen and (min-width: var.$breakpoint-lg) {
  .partners-section {
    padding: fn.spacing(8) 0;
  }

  .partners-section-header {
    padding-bottom: fn.spacing(3.5);
  }

  .partners-section-title {
    padding-bottom: fn.spacing(3.5);
  }

  .partners-section-form {
    width: 900px;
    max-width: 900px;
  }

  .submit-button {
    margin-top: fn.spacing(4);
    width: 275px;
  }
}
