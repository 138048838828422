@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  overflow: visible;
}

.grid-item-text {
  text-align: left;
}

.text-link {
  display: inline;
}

.svg-link {
  padding-top: fn.spacing(0.5);
}

.fullbg {
  background: var.$color-primary-highlight-blue;
  display: flex;
  height: 100%;
  justify-content: center;
  transition: opacity 1s ease;

  &.fullbg-selected {
    transition: opacity 1s ease;
  }
}

.fullbg-content {
  position: relative;
  display: flex;
  text-align: center;
  min-height: 65vh;

  .fullbg-content-link {
    margin-top: fn.spacing(6);
  }
}

.text-container {
  z-index: 1;
  position: relative;
  margin-bottom: -120px;
  max-width: 447px;

  &[data-container-color='blue'] {
    background-color: var.$color-primary-achieve-blue;
    padding: fn.spacing(3);
    border-radius: fn.spacing(0.5);
  }

  > * + * {
    display: block;
    margin-top: fn.spacing(3);
  }

  text-align: center;
}

.font-black {
  color: var.$color-neutral-black;
}

.font-white {
  color: var.$color-neutral-white;
}

.font-blue {
  color: var.$color-primary-achieve-blue;
}

.eyebrow {
  text-align: left;
  padding-bottom: 0;
}

/* Tablet (large or greater) styles */
@media screen and (min-width: var.$breakpoint-md) {
  .text-padding-left {
    padding-left: fn.spacing(5);
  }

  .title {
    margin-bottom: fn.spacing(2);
  }

  .sub-title {
    margin-bottom: fn.spacing(2);
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .fullbg-content {
    min-height: 723px;
    padding: fn.spacing(4) 0;
  }

  .text-container {
    margin-bottom: initial;
    text-align: left;

    &[data-container-color='blue'] {
      padding: fn.spacing(5);
    }
  }
}

@media screen and (min-width: var.$breakpoint-xl) {
  .text-container {
    margin-bottom: initial;
    max-width: initial;
  }
}

/* Ultra (large or greater) styles */
@media screen and (min-width: var.$breakpoint-xxl) {
  .fullbg-content {
    margin: 0;
    max-width: 100%;
    width: 100%;
    height: initial;
  }
}
