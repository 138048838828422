@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.flat-carousel-wrapper {
  padding-top: 0;
  color: var.$color-neutral-background-default;
}

.image-container img {
  transform: rotate(-5deg);
  filter: drop-shadow(-5px 5px 4px #0000007f);
}

.fullbg-content {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: fn.spacing(6) 0;
}

.image-container {
  min-width: 295px;
  max-width: 415px;
  aspect-ratio: 1 / 1;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.card-text-grid {
  padding-left: fn.spacing(5);
  padding-right: fn.spacing(5);
  margin-bottom: fn.spacing(4);
  text-align: left;
}

.carousel-item-title {
  margin: fn.spacing(0);
  max-width: fn.spacing(60);
}

.carousel-item-quote {
  margin: fn.spacing(3);
  max-width: fn.spacing(55);
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .fullbg-content {
    min-height: fn.spacing(55);
  }

  .text-container {
    width: 100%;
    margin: auto;
  }

  .flat-carousel-wrapper {
    // TODO: Fix styles in the base Carousel.module.scss that are overly specific which makes
    // !important override here. See `.carousel-wrapper.standard` selector.
    padding-left: fn.spacing(12) !important;
    padding-right: fn.spacing(12) !important;
  }

  .card-text-grid {
    margin-bottom: fn.spacing(0);
    padding-left: fn.spacing(8);
    padding-right: 0;
    text-align: left;
  }

  .carousel-item-title {
    margin: auto;
    max-width: fn.spacing(78);
    padding-bottom: fn.spacing(4);
  }

  .carousel-item-quote {
    margin: auto;
    max-width: fn.spacing(78);
  }
}
