@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.paginated-story-grid-section {
  padding: 0 fn.spacing(4);
}

.title {
  text-align: center;
  padding: fn.spacing(5) 0;
}

.filter {
  margin-top: fn.spacing(4);
  margin-bottom: fn.spacing(8);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: fn.spacing(2);
  margin-bottom: fn.spacing(8);
}

.news-card-grid {
  justify-content: center;
  grid-template-columns: 1fr repeat(12, minmax(auto, 60px)) 1fr;
  grid-gap: fn.spacing(5);
}

.articles-container {
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 60px));
  grid-gap: fn.spacing(5);
}

.article-grid {
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: var.$breakpoint-lg) {
  .paginated-story-grid-section {
    padding: fn.spacing(4) 0;
  }

  .filter {
    margin-top: fn.spacing(4);
    margin-bottom: fn.spacing(4);
  }

  .article-grid {
    grid-column-end: span 6;
  }
}

@media only screen and (max-width: var.$breakpoint-md) {
  .news-card-grid {
    justify-content: center;
    grid-gap: fn.spacing(3);
  }

  .article-grid {
    grid-column-end: span 12;
  }
}

@media only screen and (max-width: var.$breakpoint-xs) {
  .news-card-grid {
    justify-content: center;
    grid-template-columns: 10px repeat(6, 1fr) 10px;
    grid-gap: fn.spacing(1);
  }

  .articles-container {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: fn.spacing(2);
  }

  .article-grid {
    grid-column-end: span 6;
  }
}
