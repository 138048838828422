@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.button-typography {
  padding-top: fn.spacing(0.5);
  padding-bottom: fn.spacing(0.5);
}

.form-control > div > div > fieldset {
  border-radius: var.$achieve-default-border-radius;
}

.form-control > div {
  border-radius: var.$achieve-default-border-radius;
}

.filter-grid {
  display: inline;
  direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.label {
  margin-top: fn.spacing(2);
  margin-right: fn.spacing(5);
  margin-bottom: fn.spacing(2);
}

.filter-item-full input {
  padding: fn.spacing(2.1);
}

.filter-item-full div p {
  color: var.$color-neutral-grey-3;
}

.clear-filter {
  position: absolute;
  margin-top: fn.spacing(2);
  margin-left: fn.spacing(1);

  a {
    text-decoration: underline;
    color: var.$color-neutral-grey-3;
    cursor: pointer;
  }
}

.filter-item-full {
  width: 100%;
}

.button {
  margin: fn.spacing(1) 0;
}

.keyword-input input {
  color: var.$color-neutral-grey-3;
  font-family: 'Noto Sans', sans-serif;
  font-size: 18px;
}

.keyword-input input::placeholder {
  opacity: 1;
}

@media only screen and (min-width: var.$breakpoint-md) {
  .button {
    margin: fn.spacing(8) 0 fn.spacing(0.6) 0;
  }

  .filter-grid {
    display: flex;
    direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .filter-item-full {
    position: relative;
    padding: fn.spacing(1);
    width: 40%;
  }

  .clear-filter {
    margin-left: fn.spacing(0);
    position: absolute;
  }
}
