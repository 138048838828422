@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.carousel-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 fn.spacing(2);
}

.carousel-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &.standard {
    justify-content: unset;
  }

  &.preview {
    justify-content: flex-start;
    padding: fn.spacing(2) 0; // padding is required so shadow from PaperCarousel won't be cut off
  }

  &.preview-small {
    justify-content: flex-start;
  }

  &.preview-small-xs {
    justify-content: flex-start;
  }
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.carousel-container-item {
  flex-shrink: 0;
  scroll-snap-align: start;
  box-sizing: content-box;

  &.standard {
    width: 100%;
    height: 100%;
  }

  &.preview {
    width: 85%;
    margin: 0 var.$spacing;
  }

  &.preview-small {
    width: 80%;
    margin: 0 var.$spacing;
  }

  &.preview-small-xs {
    width: 80%;
    margin: 0 var.$spacing;
  }
}

.disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.5;
}

.carousel-control {
  display: none;
}

.control-left {
  left: 1em;
}

.control-left-center {
  left: calc(50% - 450px);
}

.control-right {
  right: 1em;
}

.control-right-center {
  right: calc(50% - 450px);
}

.dot-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
  position: relative;
  margin-top: fn.spacing(4);
}

.dot-controls-dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  outline: none;
  background-color: var.$color-neutral-white;
  opacity: 0.2;
  cursor: pointer;
  border: none;
  margin: 0 var.$spacing;
  padding: 0;
}

.dot-controls-dot-active {
  opacity: 1;
}

[data-btn-carousel-touched='true'] .carousel-control {
  background-color: var.$color-neutral-background-default;
}

/* iPad protrait or similar */
@media screen and (min-width: var.$breakpoint-md) {
  .carousel-container-item.preview {
    width: 65%;
  }

  .carousel-container-item.preview-small {
    width: 32%;
  }

  .carousel-container-item.preview-small-xs {
    width: 90%;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .no-right-padding {
    padding-right: 0;
  }

  .carousel-wrapper {
    padding: 0;

    &.preview {
      padding-left: fn.spacing(12);
    }

    &.preview-small {
      padding-left: fn.spacing(0);
    }

    &.preview-small-xs {
      padding-left: fn.spacing(0);
    }

    &.standard {
      padding-left: fn.spacing(3);
      padding-right: fn.spacing(3);
    }
  }

  .dot-controls {
    margin-top: fn.spacing(3);
  }

  .dot-controls-dot {
    width: 6px;
    height: 6px;
  }

  .dot-controls-dot-active {
    width: 6px;
    height: 6px;
  }

  .carousel-container {
    align-items: center;
  }

  .carousel-control {
    text-align: center;
    position: absolute;
    z-index: 2;
    top: calc(50% - 1em);
    display: inline-flex;
    align-items: center;
  }

  .carousel-container-item.preview {
    width: 72%;
    margin: 0 fn.spacing(2);

    &:last-child {
      margin-right: fn.spacing(19);
    }
  }

  .carousel-container-item.preview-small {
    width: 30%;
    margin: 0 fn.spacing(3);

    &:last-child {
      margin-right: fn.spacing(19);
    }

    &:first-child {
      padding-left: fn.spacing(11);
    }
  }

  .carousel-container-item.preview-small-xs {
    width: 30%;
    margin: 0 fn.spacing(3);

    &:last-child {
      margin-right: fn.spacing(19);
    }

    &:first-child {
      padding-left: fn.spacing(11);
    }
  }
}
@media screen and (min-width: var.$breakpoint-xl) {
  .carousel-container-item.preview {
    width: 70%;
  }

  .carousel-container-item.preview-small {
    width: 27.5%;
  }

  .carousel-container-item.preview-small-xs {
    width: 27.5%;
  }
}

@media screen and (max-width: var.$breakpoint-xl) {
  .carousel-container-item.preview-small div {
    background-color: transparent;
  }

  .carousel-container-item.preview-small-xs div {
    background-color: transparent;
  }
}
