@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.placeholder {
  color: var.$color-neutral-grey-3;
}

.section {
  margin: fn.spacing(4);
}

.title {
  text-align: center;
}

.text-box-right {
  > div > div {
    border-radius: 10px;
  }
}

.text-box-left {
  > div > div {
    border-radius: 10px;
  }
}

.long-input {
  > div > div {
    border-radius: 10px;
  }
}

.information-container {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-evenly';
}

.box-text {
  margin-top: fn.spacing(1);
}

.title-text {
  margin-top: fn.spacing(5);
}

.main-title-text {
  margin-top: fn.spacing(4);
}

.submit-button {
  margin-top: fn.spacing(4);
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sent-title {
  margin: fn.spacing(5);
  text-align: center;
}

@media only screen and (min-width: var.$breakpoint-xl) {
  .section {
    margin: fn.spacing(6) fn.spacing(23);
  }

  .title {
    text-align: center;
  }

  .text-box-right {
    margin-top: fn.spacing(1);
    padding-left: fn.spacing(2);

    > div > div {
      border-radius: 10px;
    }
  }

  .text-box-left {
    margin-top: fn.spacing(1);
    padding-right: fn.spacing(2);

    > div > div {
      border-radius: 10px;
    }
  }

  .long-input {
    > div > div {
      border-radius: 10px;
    }
  }

  .information-container {
    display: 'flex';
    flex-direction: 'row';
    justify-content: 'space-evenly';
  }

  .box-text {
    margin-top: fn.spacing(1);
  }

  .title-text {
    margin-top: fn.spacing(5);
  }

  .main-title-text {
    margin-top: fn.spacing(4);
  }

  .submit-button {
    margin-top: fn.spacing(4);
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
