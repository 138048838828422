@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.pill-anchor > p {
  width: fit-content;
  padding: fn.spacing(0.5) fn.spacing(1);
  margin: 0 fn.spacing(2) fn.spacing(2.5) 0;
  border-radius: 20% / 100%;
  background-color: var.$color-primary-highlight-blue;
}

.pill-content {
  padding: fn.spacing(0) fn.spacing(0.5);
}
