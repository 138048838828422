@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.story-blocks-container {
  display: flex;
  flex-direction: column;
  padding: fn.spacing(6) fn.spacing(2);

  .story-blocks-grid {
    display: flex;
    flex-direction: column;

    .story-blocks-item {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      margin-bottom: fn.spacing(3);

      .story-blocks-item-img {
        overflow: hidden;
      }

      .story-blocks-item-content {
        max-width: 290px;

        .story-blocks-item-author {
          margin-top: fn.spacing(4);
          margin-bottom: fn.spacing(2);
          color: var.$color-primary-achieve-blue;
          text-align: center;
        }

        .story-blocks-item-title {
          margin-bottom: fn.spacing(2);
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (min-width: var.$breakpoint-md) {
  .block-img {
    width: 100%;
    height: 760px;
    position: relative;
  }

  .story-blocks-container {
    padding: 0;

    .story-blocks-grid {
      .story-blocks-item {
        flex-direction: row-reverse;
        min-height: 760px;
        margin-bottom: 0;

        .story-blocks-item-img {
          min-width: 50%;
          max-width: 50%;
        }

        .story-blocks-item-content {
          padding: 0 fn.spacing(8);
          min-width: 50%;
          max-width: 50%;

          .story-blocks-item-author {
            margin-top: 0;
            text-align: left;
          }

          .story-blocks-item-title {
            text-align: left;
          }
        }

        &[data-align-left='true'] {
          flex-direction: row;
        }
      }
    }
  }
}
