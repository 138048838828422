@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.multi-slider-slide {
  min-height: 102px; // This is used to give the slider a height with which the logos can be vertically centered
  position: relative;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.multi-slider-item {
  flex: 1;
  position: relative;
  max-width: 160px;
  min-width: 120px;
  padding: fn.spacing(2);
}

.multi-slider-container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  position: relative;

  &[data-narrow] {
    max-width: 100%;
  }
}

.multi-slider-section {
  overflow: hidden;
  padding: fn.spacing(4) 0;
}

.multi-slider-indicator-dots {
  background-color: var.$color-neutral-grey-3;

  &[aria-pressed='true'] {
    opacity: 1;
    background-color: var.$color-primary-achieve-blue;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .multi-slider-section {
    padding: fn.spacing(8) 0;
  }
}
