@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.featured-article-carousel section {
  background-color: var.$color-primary-highlight-blue;
}

.section {
  background-color: var.$color-neutral-background-default;
  padding-top: fn.spacing(8);
  padding-bottom: fn.spacing(8);
}

.press-item {
  background-color: var.$color-neutral-background-default;
}

.paper {
  border: 1px solid var.$color-paper-white;
  border-radius: 16px;
  padding: fn.spacing(5);
}

.paper-carousel-title-divider {
  margin-bottom: fn.spacing(6);
}

.image {
  border-radius: 12px;
}

.card-text-grid {
  padding-left: fn.spacing(5);
  padding-right: fn.spacing(5);
  text-align: center;
}

.lead-text {
  color: var.$color-neutral-text-weak;
  margin-bottom: fn.spacing(0.5);
}

.heading-text {
  color: var.$color-neutral-text-onlight;
  margin-bottom: fn.spacing(2);
  display: inline;
}

.date {
  color: var.$color-neutral-black;
  margin: fn.spacing(1.5) 0 fn.spacing(1.5);
}

.supporting-text {
  color: var.$color-neutral-eighty;
  margin-bottom: fn.spacing(1.5);
}

.link {
  text-decoration: none;

  p {
    margin: 0;
  }
}

.link:hover {
  text-decoration-color: var.$color-neutral-text-onlight;
}

.link-icon-container {
  display: inline-flex;
}

.external-link {
  margin-left: fn.spacing(0.5);
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .card-text-grid {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }

  .no-right-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .section {
    padding-top: fn.spacing(8);
    padding-bottom: fn.spacing(11);
    padding-right: 0;
  }

  .paper-carousel-title-divider {
    margin-bottom: fn.spacing(8);
  }

  .lead-text {
    color: var.$color-neutral-gray-eighty;
    margin-bottom: fn.spacing(1.5);
  }

  .date {
    margin: fn.spacing(1.5) 0 fn.spacing(4);
  }

  .supporting-text {
    color: var.$color-neutral-black;
    margin-bottom: fn.spacing(4);
    margin-top: fn.spacing(2);
  }
}
