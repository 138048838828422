@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.block-img {
  width: 100%;
  height: 175px;
  min-height: 100%;
  position: relative;
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 fn.spacing(1);
  text-align: center;
  width: 100%;
}

.text-center p {
  width: 100%;
}

.text-heading {
  text-align: center;
  margin: fn.spacing(3.75);
}

.blue-box {
  align-items: center;
  background-color: var.$color-primary-achieve-blue;
  color: var.$color-neutral-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 175px;
  overflow: hidden;
  padding: fn.spacing(4) 0;
  position: relative;
  width: 100%;
}

.disclaimer {
  color: var.$color-neutral-grey-4;
  max-width: 279px;
  padding: 0;
  margin: fn.spacing(1) 0 0;
}

.hr {
  width: 88px;
  height: 0;
  border: 2px solid var.$color-primary-achieve-blue;
  border-radius: 4px;
  margin-top: fn.spacing(11);
  margin-bottom: fn.spacing(2);
}

.box-media-text {
  overflow: hidden;
}

@media only screen and (min-width: var.$breakpoint-sm) {
  .block-img {
    height: 225px;
  }

  .blue-box {
    min-height: 225px;
  }
}

@media only screen and (min-width: var.$breakpoint-md) {
  .text-center {
    text-align: left;
    padding: 0 fn.spacing(8);
  }

  .disclaimer {
    text-align: left;
  }
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .text-center {
    height: auto;
    text-align: left;
  }

  .block-img {
    height: 350px;
  }

  .blue-box {
    min-height: 350px;
  }

  .disclaimer {
    margin-top: fn.spacing(6);
  }
}
