@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  padding: fn.spacing(4) 0;
  text-align: center;
}

.card-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon {
  color: var.$color-primary-achieve-blue;
  font-size: 28px;
  margin-right: fn.spacing(0.5);
}

.title {
  padding-bottom: fn.spacing(2);
}

/* Tablet (large or greater) styles */
@media screen and (min-width: var.$breakpoint-sm) {
  .card-grid {
    align-items: stretch;
  }

  .container {
    max-width: fn.spacing(40);
    margin: auto;
  }

  .title {
    padding-bottom: fn.spacing(4);
  }

  .section {
    display: flex;
    justify-content: center;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding: fn.spacing(5);
  }
}
